<template>
  <div>
    <h1 class="mb-5">{{ $route.name }}</h1>
    <b-card>
      <b-row no-gutters>
        <b-col cols="8" class="mb-5">
          <div class="row">
            <b-col cols="12">
              <b-input-group prepend="Sekolah" class="mb-3">
                <b-form-select v-model="paging.cabang" :options="opt.cabang" @change="doGetReport()"/>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group prepend="Dari Tanggal">
                <b-form-datepicker v-model="paging.from" @input="doGetReport()"/>
              </b-input-group>
            </b-col>
            <b-col>
              <b-input-group prepend="Sampai Tanggal">
                <b-form-datepicker v-model="paging.to" @input="doGetReport()"/>
              </b-input-group>
            </b-col>
          </div>
        </b-col>
        <b-col cols="4" class="d-flex justify-content-end align-items-start">
          <b-button-group>
            <b-button
              text="Button"
              variant="danger"
              @click="
                $bvModal.show('modal-pdf');
                doGetReport();
              "
            >
              PDF
            </b-button>
            <b-button text="Button" variant="success" @click="exportXls()">
              XLS
            </b-button>
          </b-button-group>
        </b-col>
        <b-col cols="12">
          <table class="table table-bordered table-striped">
            <thead>
              <tr class="text-center">
                <th style="width:5%">No</th>
                <th style="width:10%">Tanggal</th>
                <th style="width:10%">Tgl. Voucher</th>
                <th>Keterangan</th>
                <th style="width:15%">Jumlah</th>
              </tr>
            </thead>
            <tbody v-if="report.items.length > 0">
              <tr
                v-for="(report, reportIndex) in report.items"
                :key="`report-${reportIndex}`"
              >
                <td class="text-center ">{{ reportIndex + 1 }}</td>
                <td class="text-center ">
                  <div class="px-2">
                    {{ dateFormatId(report.trx_date) }}
                  </div>
                </td>
                <td class="text-center ">
                  <div class="px-2">
                    {{ dateFormatId(report.voucher_date) }}
                  </div>
                </td>
                <td class="">
                  <div class="px-2">
                    {{ report.description }}
                  </div>
                </td>
                <td>
                  <div class="text-right px-2">
                    <span style="border: 0" class="text-right">
                      {{ thousand(report.jumlah) }}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td colspan="12">There's no data to display...</td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      title="PREVIEW LAPORAN TRANSAKSI JURNAL"
      id="modal-pdf"
      hide-footer
      size="xl"
      centered
    >
      <div id="table-print" class="p-5">
        <h5 class="text-center">
          MADANI ISLAMIC SCHOOL
        </h5>
        <h5 class="text-center">LAPORAN JURNAL TRANSAKSI</h5>
        <h5 class="text-center" v-show="report.cabang">{{ report.cabang }}</h5>
        <h6 class="text-center mb-5 pb-5" v-show="report.from && report.to">
          Tanggal {{ dateFormatId(report.from) }} s.d
          {{ dateFormatId(report.to) }}
        </h6>
        <table class="table table-bordered table-striped">
          <thead>
            <tr class="text-center">
              <th>No</th>
              <th style="width:10%">Tanggal</th>
              <th style="width:10%">Tgl. Voucher</th>
              <th style="width:25%">Keterangan</th>
              <th>No Akun</th>
              <th>Debit</th>
              <th>Kredit</th>
            </tr>
          </thead>
          <tbody v-if="report.items.length > 0">
            <tr
              v-for="(report, reportIndex) in report.items"
              :key="`report-${reportIndex}`"
            >
              <td class="text-center ">{{ reportIndex + 1 }}</td>
              <td class="text-center ">
                <div class="px-2">
                  {{ dateFormatId(report.trx_date) }}
                </div>
              </td>
              <td class="text-center ">
                <div class="px-2">
                  {{ dateFormatId(report.voucher_date) }}
                </div>
              </td>
              <td class="">
                <div class="px-2">
                  {{ report.description }}
                </div>
              </td>
              <td class="">
                <div
                  v-for="(dtl, dtlIndex) in report.detail"
                  :key="`dtl-${dtlIndex}`" class="px-2"
                >
                  <td style="border: 0">
                    {{ dtl.kode_gl }} - {{ dtl.nama_gl }}
                  </td>
                </div>
              </td>
              <td class="px-1">
                <div
                  v-for="(dtl, dtlIndex) in report.detail"
                  :key="`dtl-${dtlIndex}`" class="text-right px-2"
                >
                  <span style="border: 0" class="text-right">
                    {{ dtl.flag_dc == "D" ? thousand(dtl.amount) : 0 }}
                  </span>
                </div>
              </td>
              <td class="px-1">
                <div
                  v-for="(dtl, dtlIndex) in report.detail"
                  :key="`dtl-${dtlIndex}`" class="text-right px-2"
                >
                  <span style="border: 0" class="text-right">
                    {{ dtl.flag_dc == "C" ? thousand(dtl.amount) : 0 }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="text-center">
              <td colspan="12">There's no data to display...</td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex justify-content-end border-top pt-5"
        >
          <b-button variant="secondary" @click="$bvModal.hide('modal-pdf')"
            >Cancel
          </b-button>
          <b-button
            variant="danger"
            type="button"
            class="ml-3"
            @click="doPrintPdf()"
          >
            Cetak PDF
          </b-button>
          <b-button
            variant="warning"
            type="button"
            class="ml-3"
            @click="doSavePdf()"
          >
            Simpan PDF
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
  
<script>
import helper from "@/core/helper";
import html2pdf from "html2pdf.js";
import { mapGetters } from "vuex";
import easycoApi from "@/core/services/easyco.service";
export default {
  name: "LaporanTransaksiJurnal",
  components: {},
  data() {
    return {
      table: {
        items: [],
        loading: false,
      },
      report: {
        items: [],
        loading: false,
        totalRows: 0,
        cabang: null,
        from: null,
        to: null,
      },
      paging: {
        page: 1,
        perPage: 10,
        sortDesc: true,
        sortBy: "kop_anggota.id",
        search: "",
        status: "~",
        cabang: 0,
        from: null,
        to: null,
      },
      opt: {
        cabang: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    paging: {
      handler(val) {},
      deep: true,
    },
  },
  mounted() {
    this.doGetCabang();
  },
  methods: {
    ...helper,
    doPrintPdf() {
      let filename = "LAPORAN JURNAL TRANSAKSI";
      if (this.report.cabang) {
        filename += ` - Sekolah ${this.report.cabang}`;
      }
      if (this.report.from && this.report.to) {
        filename += ` - Dari ${this.dateFormatId(
          this.report.from
        )} Sampai ${this.dateFormatId(this.report.to)}`;
      }
      let element = document.getElementById("table-print");
      let options = {
        margin: 0,
        filename: `${filename}.pdf`,
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "landscape",
        },
      };
      html2pdf()
        .set(options)
        .from(element)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          console.log("hi");
          window.open(pdf.output("bloburl"), "_blank");
        });
    },
    doSavePdf() {
      let filename = "LAPORAN JURNAL TRANSAKSI";
      if (this.report.cabang) {
        filename += ` - Sekolah ${this.report.cabang}`;
      }
      if (this.report.from && this.report.to) {
        filename += ` - Dari ${this.dateFormatId(
          this.report.from
        )} Sampai ${this.dateFormatId(this.report.to)}`;
      }

      html2pdf(document.getElementById("table-print"), {
        margin: 0,
        filename: `${filename}.pdf`,
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "landscape",
        },
      });
    },
    async exportXls() {
      let payload = `kode_cabang=${this.paging.cabang}&from_date=${this.paging.from}&thru_date=${this.paging.to}`;
      let req = await easycoApi.jurnalTransaksiExcel(payload);
      const url = window.URL.createObjectURL(new Blob([req.data]));
      const link = document.createElement("a");
      let fileName = "Jurnal_Transaksi.xls";
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },
    getCabangName(id) {
      if (id > 0) {
        let cabangName = this.opt.cabang.find((i) => i.value == id);
        if (cabangName) {
          console.log(cabangName.text);
          return cabangName.text;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    async doGetCabang() {
      let payload = {
        perPage: "~",
        page: 1,
        sortBy: "nama_cabang",
        sortDir: "ASC",
        search: "",
      };
      try {
        let req = await easycoApi.cabangRead(payload, this.user.token);
        let { data, status, msg } = req.data;
        if (status) {
          this.opt.cabang = [
            {
              value: 0,
              text: "All",
            },
          ];
          data.map((item) => {
            this.opt.cabang.push({
              value: item.kode_cabang,
              text: `${item.kode_cabang} - ${item.nama_cabang}`,
            });
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async doGetReport() {
      let payload = {
        kode_cabang: this.paging.cabang,
        from_date: this.paging.from,
        thru_date: this.paging.to,
      };
      this.report.loading = true;
      this.report.from = payload.from_date;
      this.report.to = payload.thru_date;
      this.report.cabang = this.getCabangName(payload.kode_cabang);
      try {
        let req = await easycoApi.laporanJurnalTransaksi(
          payload,
          this.user.token
        );
        let { data, status, msg, total } = req.data;
        if (status) {
          data.map((item) => {
            item.jumlah = 0
            item.detail.map((dt) => {
              if(dt.flag_dc == "D") {
                item.jumlah += Number(dt.amount)
              }
            })
          })
          this.report.items = data;
          this.report.totalRows = total;
        } else {
          this.notify("danger", "Error", msg);
        }
        this.report.loading = false;
      } catch (error) {
        this.report.loading = false;
        console.error(error);
        this.notify("danger", "Login Error", error);
      }
    },
    doInfo(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right",
      });
    },
    notify(type, title, msg) {
      this.$bvToast.toast(msg, {
        title: title,
        autoHideDelay: 5000,
        variant: type,
        toaster: "b-toaster-bottom-right",
        appendToast: true,
      });
    },
  },
};
</script>
